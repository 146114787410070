<template>
  <div class="">
    <div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
      <div class="tableConTable">
        <div class="tableConTop">
          <el-row>
            <el-col :span="5" class="tableConTopLeft">
              <h3>
                <el-button type="primary" round icon="el-icon-back" size="mini" @click="$router.push({'name':'WhFinance'})">{{$t('i18nn_25761be67f072851')}}</el-button>
                <span class="tct_tit">{{$t('i18nn_25593efaf75ce5af')}}</span>
                <!-- <el-tooltip effect="dark" :content="$t('i18nn_dc14da6ede09b7f1')" placement="top"> -->
                <!-- <el-button type="primary" circle icon="el-icon-setting" size="mini" @click="selShowHeader()" ></el-button> -->
                <!-- </el-tooltip> -->
                <!-- <el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
                          <el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
                        </el-tooltip> -->
              </h3>
            </el-col>
            <el-col :span="19" class="tableConTopRig">
              <!-- <el-button  @click="updatePartnerDataAction($event,$t('4e9fc68608c60999'))" type="danger" size="small" icon="el-icon-close">{{$t('i18nn_4f62f8d07bb4054f')}}</el-button> -->
              <!-- <span v-if="!isSel"> -->
              <!-- <el-button @click="addAction($event)" type="primary" size="small" icon="el-icon-plus">{{$t('c0246c55b9cac963')}}</el-button> -->

              <!-- <el-button @click="completeAction(null)" type="danger" size="small" icon="el-icon-finished">{{$t('i18nn_785c25ad46aa1a92')}}</el-button> -->
              <el-button @click="delAction($event, null)" type="danger" size="mini" icon="el-icon-minus">{{$t('e33c9b93c36fd250')}}</el-button>
              <!-- </span> -->
              <!--右边容器-->
              <!-- <slot name="topRight"></slot> -->
              <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
            </el-col>
          </el-row>
        </div>

        <div>
          <div class="filterCon">
            <!-- <el-row type="flex" justify="end"> -->
              <!-- <el-col :span="24"> -->
                <ul class="filterConList">
                  <!-- <li>
                    <span>{{$t('i18nn_cabc5dc617eef30e')}}</span>

                    <el-date-picker
                      v-model="filterData.statisticsCurrentDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      size="small"
                      :placeholder="$t('68efabd029c860a9')"
                      @change="initData()"
                    ></el-date-picker>
                  </li> -->
                  <!-- <li>
                    <span>{{$t('6cdece641436d7ab')}}</span>
                    <el-select filterable clearable v-model="filterData.plStatue" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
                      <el-option v-for="item in selectOption.plStatueList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </li>
                  <li>
                    <span>{{$t('i18nn_6bfc5f83ed58481a')}}</span>
                    <el-input
                      type="text"
                      v-model="filterData.plNo"
                      size="small"
                      clearable
                      @keyup.enter.native="initData()"
                      maxlength="50"
                      :placeholder="$t('hytxs0000001')"
                      style="width: 180px;"
                    />
                  </li>
                  <li>
                    <span>{{$t('i18nn_a332a38ed3832d9d')}}</span>
                    <el-input
                      type="text"
                      v-model="filterData.putWhNo"
                      size="small"
                      clearable
                      @keyup.enter.native="initData()"
                      maxlength="50"
                      :placeholder="$t('hytxs0000001')"
                      style="width: 180px;"
                    />
                  </li>
                  <li>
                    <span>{{$t('i18nn_e0c16ac094cc2bce')}}</span>
                    <el-input
                      type="text"
                      v-model="filterData.goodsSku"
                      size="small"
                      clearable
                      @keyup.enter.native="initData()"
                      maxlength="50"
                      :placeholder="$t('hytxs0000001')"
                      style="width: 180px;"
                    />
                  </li> -->
                  <li>
                    <span>{{$t('i18nn_a51a59d19387d567')}}</span>
                    <el-input
                      type="text"
                      v-model="filterData.opNo"
                      size="small"
                      clearable
                      @keyup.enter.native="initData()"
                      maxlength="50"
                      :placeholder="$t('hytxs0000001')"
                      style="width: 180px;"
                    />
                  </li>
                  <!-- <li>
                    <span>{{$t('i18nn_9eeccc6d6f1ff32f')}}</span>
                    <el-input
                      type="text"
                      v-model="filterData.goodsName"
                      size="small"
                      clearable
                      @keyup.enter.native="initData()"
                      maxlength="50"
                      :placeholder="$t('hytxs0000001')"
                      style="width: 180px;"
                    />
                  </li> -->
                  <li><el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{$t('1e7246dd6ccc5539')}}</el-button></li>
                </ul>
              <!-- </el-col> -->
            <!-- </el-row> -->
          </div>
        </div>

        <el-table
          id="out-table2"
          ref="multipleTable"
          :data="tableData"
          stripe
          :border="true"
          :height="$store.state.tableMaxHeight2"
          @row-click="handleCurrentChange"
          @selection-change="handleSelectionChange"
          style="width: 100%"
          size="small"
        >
          <!-- <el-table-column type="index" :index="$Utils.tableIndex(pagination)" :index="indexMethod" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column> -->
          <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column>

          <!-- "id":"",
                "putWhNo":$t('i18nn_a332a38ed3832d9d'),
                "goodsSku":$t('i18nn_e0c16ac094cc2bce'),
                "goodsName":$t('i18nn_9eeccc6d6f1ff32f'),
                "goodsImg":$t('i18nn_877c5a0e44a0eb07'),
                "pShelveCount":$t('i18nn_b071e67922639ea1'),
                "pShelveVolAll":$t('i18nn_bb0b485a98f17aea'),
                "pShelveWeightAll":$t('i18nn_4157ea5a8c62064b'),
                "placeNo":$t('hytxs0000035') -->

          <!-- <el-table-column :label="$t('i18nn_8bdf7efaeff69d77')"> -->
          <el-table-column prop="opNo" :label="$t('i18nn_a51a59d19387d567')"></el-table-column>

          <!-- <el-table-column prop="opStatus" :label="$t('6cdece641436d7ab')"></el-table-column> -->

          <el-table-column prop="finName" :label="$t('i18nn_a4eca6ff2588d791')"></el-table-column>

          <el-table-column prop="finAmt" :label="$t('i18nn_16658a855f07d171')"></el-table-column>

          <el-table-column prop="currencyName" :label="$t('i18nn_dceb9f39f0d789cc')"></el-table-column>
          <el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
          	<template slot-scope="scope">
          		<el-popover placement="top"
          	    trigger="hover"
          	    ><div class="pre-text">{{ scope.row.remark }}</div>
          	    <span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
          	  </el-popover>
          	</template>
          </el-table-column>
          <!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->

          <!-- <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column> -->

          <el-table-column :label="$t('93f5ca01b006206c')" width="250px" fixed="right">
            <template slot-scope="scope">
              <!-- <div v-if="isSel">
                <el-button @click="selRow($event, scope.row, scope.$index)" v-if="!scope.row.dataFlag" type="warning" size="mini" icon="el-icon-magic-stick">{{$t('7fb6ada66a1fccca')}}</el-button>
              </div> -->
              <!-- <div v-else> -->
              <!-- <el-button @click="openEdit($event, scope.row, scope.$index)" v-if="!scope.row.dataFlag" type="warning" size="mini" icon="el-icon-edit">{{$t('6267f3aedf895209')}}</el-button>

                <el-button @click="delAddAction($event, scope.row, scope.$index)" v-if="'add' == scope.row.dataFlag" type="warning" size="mini" icon="el-icon-minus">
{{$t('e33c9b93c36fd250')}}
                </el-button>
                <el-button @click="editRefreshAction($event, scope.row, scope.$index)" v-if="'edit' == scope.row.dataFlag" type="warning" size="mini" icon="el-icon-refresh-right">{{$t('i18nn_619a3387e6517f01')}}</el-button>

                <el-button
                  @click="saveAction($event, scope.row, scope.$index)"
                  v-if="'add' == scope.row.dataFlag || 'edit' == scope.row.dataFlag"
                  type="success"
                  size="mini"
                  icon="el-icon-check"
                >
 {{$t('4ce9979bfb6576d9')}}
                </el-button> -->

              <!-- <el-button @click="openHistory(scope.row)" type="warning" size="mini">{{$t('i18nn_8bafd5202547e50d')}}</el-button> -->
              <!-- <el-button @click="completeAction($event,scope.row)" v-if="!scope.row.dataFlag" type="danger" size="mini" icon="el-icon-finished">{{$t('i18nn_785c25ad46aa1a92')}}</el-button> -->
              <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t('e33c9b93c36fd250')}}</el-button>
              <!-- </div> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
    </div>

    <!-- 产品图片上传 -->
    <!-- <el-dialog :title="$t('i18nn_218ff32b6139e98b')" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="500px">
      <hyUpLoadImg
        ref="hyUpLoadImg1"
        :imgId="imgId"
        :imgwidth="200"
        :imgheight="200"
        :imgKey="'WhShelves'"
        :bucket="'huyi-oss-user'"
        :maxSizeMB="5"
        :size="'small'"
        :autoOrient="true"
        :autoResize="true"
        v-on:changeImgUrl="changeImgUrl1"
      >
        <div slot="imgDesc">
          <span style="display: inline-block; width: 200px; font-size: 14px; padding-bottom: 5px;">
            支持png、jpg、gif等图片格式;
            <br />
            大小不超过5M;
            <br />
          </span>
        </div>
      </hyUpLoadImg>
    </el-dialog> -->

    <!--查看图片-->
    <!-- <el-dialog :title="$t('2674282277c3714e')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('4e9fc68608c60999')}}</el-button></div>
    </el-dialog> -->

    <!--选择入库数据-->
    <!-- <el-dialog :title="$t('7fb6ada66a1fccca')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
      <div style="">
      </div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogSelVisible = false">{{$t('4e9fc68608c60999')}}</el-button></div>
    </el-dialog> -->
  </div>
</template>
<script>
// import { deepClone } from '@/utils/utils.js';



export default {
  components: {
    // WhInGoods
  },
  // props: {
  //   // mobile:"",
  //   isSel: {
  //     default: function() {
  //       return false;
  //     },
  //     type: Boolean
  //   }
  // },
  data() {
    return {
      //上传图片
      // dialogFormVisible: false,
      // imgId: this.$store.getters.getUserInfo.id,
      // openRowData: {},
      // openRowIndex: {},

      // //图片放大
      // dialogImgVisible: false,
      // imgUrlBigShow: '',

      //打开入库选择框
      // dialogSelVisible: false,

      //选择
      currentSel: {},
      //表格数据
      //loading,表格数据
      loading: false,
		loading_load: false,
      //表格数据
      tableData: [],
      //缓存，原数据
      // tableDataCatch: [],

      //分页数据
      pagination: this.$Utils.defaultPagination(),
      //表格选择的数据
      multipleSelection: [],
      //查询，排序方式
      //"orderBy": "auditTime,fCon,goodsCon", //排序字段
      selectOption: {
        plStatueList: [
          {
            value: '',
            label: this.$t('16853bda54120bf1')
          },
          {
            value: '1',
            label: this.$t('i18nn_9cee201b82dbc9cb')
          },
          {
            value: '2',
            label: this.$t('1ff1fd8ddb3e0e8d')
          },
          {
            value: '3',
            label: this.$t('i18nn_821531c6e29bd318')
          },
          {
            value: '4',
            label: this.$t('i18nn_cadfbfa511d00eb1')
          },
          {
            value: '5',
            label: this.$t('i18nn_4b95be19dadf6f0a')
          },
          {
            value: '6',
            label: this.$t('i18nn_5599155c3578c611')
          }
        ]
      },

      filterData: {
        orderBy: 'create_time', //排序字段
        sortAsc: 'desc', //desc降序，asc升序

        // plStatue: '',
        opNo: ''
        // putWhNo: '',
        // goodsSku: '',
        // goodsName: ''
      }
    };
  },
  // watch: {
  //   $route(to, from) {
  //     if (this.$route.query && this.$route.query.opNo) {
  //       this.filterData.opNo = this.$route.query.opNo;
  //     }
  //     this.initData();
  //   }
  // },
	activated() {
		if (this.$route.query && this.$route.query.opNo) {
		  this.filterData.opNo = this.$route.query.opNo;
		}
		this.initData();
	},
  //创建时
  created() {
    // if (this.$route.query && this.$route.query.opNo) {
    //   this.filterData.opNo = this.$route.query.opNo;
    // }
    // this.initData();
  },
  //编译挂载前
  mounted() {},
  methods: {
    initData() {
      //跳转到第一页
      this.pagination.current_page = 1;
      this.currentSel = {};
      this.multipleSelection = [];

      this.getPageData();
      // 部门数据
      // this.getProvinceData();
      // this.getDetData("");
    },

    //打开选择数据
    // openSelData(event, row, index) {
    //   event.stopPropagation();

    //   this.dialogSelVisible = true;
    //   this.openRowData = row;
    //   this.openRowIndex = index;
    //   // this.$nextTick(() => {
    //   //   this.$refs.hyUpLoadImg1.openFile();
    //   // });
    // },
    // //选择数据后回调
    // selWhGoodsData(selRow) {
    //   this.dialogSelVisible = false;

    //   let row = this.openRowData;
    //   row.putWhNo = selRow.putWhNo;
    //   row.goodsSku = selRow.goodsSku;
    //   row.goodsImg = selRow.goodsImg;
    //   row.goodsName = selRow.goodsName;
    //   this.$set(this.tableData, this.openRowIndex, row);

    //   // this.openRowData = row;
    //   // this.openRowIndex = index;
    //   // this.$nextTick(() => {
    //   //   this.$refs.hyUpLoadImg1.openFile();
    //   // });
    // },

    //作为组件选择行数据
    // selRow(event, row, index) {
    //   event.stopPropagation();
    //   this.$emit('selectRow', row);
    // },
    //打开图片上传
    // openUpLoadImg(row, index) {
    //   this.dialogFormVisible = true;
    //   this.openRowData = row;
    //   this.openRowIndex = index;
    //   this.$nextTick(() => {
    //     this.$refs.hyUpLoadImg1.openFile();
    //   });
    // },
    //清除图片
    // clearUpLoadImg(row,index){
    //   row.goodsImg = '';
    //   this.$set(this.tableData, index, row);
    // },

    //图片改变
    // changeImgUrl1(childValue) {
    //   console.log('changeImgUrl1', childValue);
    //   // childValue就是子组件传过来的值

    //   let row = this.openRowData;
    //   row.goodsImg = childValue;
    //   this.$set(this.tableData, this.openRowIndex, row);
    //   this.dialogFormVisible = false;
    //   // this.$set(this.tableDataCatch, this.openRowIndex, row);
    //   // this.myForm.imgList = [childValue];
    // },

    //查看图片
    // openBigImg(event, imgsrc) {
    //   event.stopPropagation();
    //   console.log('openBigImg', imgsrc);
    //   this.dialogImgVisible = true;
    //   this.imgUrlBigShow = imgsrc;
    // },

    //选择行
    handleCurrentChange(row, event, column) {
      this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
    },
    //多选
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },

    //新增
    // addAction() {
    //   console.log('addAction');
    //   this.tableData.unshift({
    //     dataFlag: 'add',

    //     // "cusNo":this.$t('i18nn_64fed2851f79d875'),
    //     // "packageNo":this.$t('i18nn_7b27c6b2a3f93412'),
    //     // "goodsSku":this.$t('i18nn_e0c16ac094cc2bce'),
    //     // "goodsImg":this.$t('i18nn_877c5a0e44a0eb07'),
    //     // "goodsName":this.$t('i18nn_9eeccc6d6f1ff32f'),
    //     // "weightUnit":"11",//this.$t('i18nn_8e01866868930d09'),//this.$t('i18nn_8e01866868930d09'),
    //     // "goodsWeight":"1.122",//this.$t('i18nn_ad4b0047e16d89ae'),
    //     // "volumeUnit":"22",//this.$t('i18nn_57936a4767ded7f5'),//this.$t('i18nn_57936a4767ded7f5'),
    //     // "goodsLength":"1",//长",
    //     // "goodsWidth":"2",//宽",
    //     // "goodsHeight":"3",//高",
    //     // "goodsCount":"123",//入库件数",

    //     // "id":"",
    //     putWhNo: '',
    //     goodsSku: '',
    //     goodsName: '',
    //     goodsImg: '',
    //     pShelveCount: '', //实际上架数",
    //     pShelveVolAll: '', //this.$t('i18nn_bb0b485a98f17aea'),
    //     pShelveWeightAll: '', //this.$t('i18nn_4157ea5a8c62064b'),
    //     placeNo: '' //this.$t('hytxs0000035')
    //   });
    // },
    //删除新增
    // delAddAction(event, row, index) {
    //   event.stopPropagation();
    //   console.log('delAddAction', index);
    //   this.tableData.splice(index, 1);
    // },
    // //编辑
    // openEdit(event, row, index) {
    //   event.stopPropagation();
    //   console.log('openEdit', row);
    //   row.dataFlag = 'edit';
    //   this.$set(this.tableData, index, row);
    // },
    //取消编辑，还原数据
    // editRefreshAction(event, row, index) {
    //   event.stopPropagation();
    //   console.log('editRefreshAction', row);
    //   // row.dataFlag = '';
    //   console.log('old_row_data11', this.tableDataCatch);
    //   // let old_row_data = row;
    //   let old_row_data_f = this.tableDataCatch.find(item => {
    //     return item.id == row.id;
    //   });
    //   let old_row_data = deepClone(old_row_data_f); //深拷贝

    //   // let old_row_data = Object.assign(old_row_data_f,{});//浅拷贝
    //   // let old_row_data_index = this.tableDataCatch.indexOf(item=>{
    //   //   return item.id == row.id;
    //   // });
    //   // let old_row_data = this.tableDataCatch[old_row_data_index];
    //   // .map(function (item) { //接收新数组
    //   //   return item * item;
    //   // });
    //   console.log('old_row_data', old_row_data);
    //   // this.tableData
    //   this.$set(this.tableData, index, old_row_data);
    //   // this.$forceUpdate();
    // },
    //保存
    // saveAction(event, row, index) {
    //   event.stopPropagation();
    //   console.log('saveAction', row);
    //   this.postData(this.$urlConfig.WhPlRecUpdate, [row], () => {
    //     if (row.id) {
    //       //不刷新数据，仅改变编辑状态
    //       row.dataFlag = null;
    //       this.$set(this.tableData, index, row);
    //       this.$set(this.tableDataCatch, index, row);

    //       // this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
    //       //   type: 'success',
    //       //   
    //       // });
    //       this.$message.success('编辑成功！');
    //     } else {
    //       this.initData();
    //       // this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
    //       //   type: 'success',
    //       //   
    //       // });
    //       this.$message.success('新增成功！');
    //     }
    //   });
    // },
    //完成收货
    // completeAction(event, row) {
    //   event.stopPropagation();
    //   console.log('completeAction', row);
    //   let parm = [];

    //   if (!!row) {
    //     //单条
    //     parm = [row.id];
    //   } else {
    //     //多条
    //     let dataList = this.multipleSelection;
    //     if (dataList.length < 1) {
    //       this.$message.warning(this.$t('7b80e66b535a3732'));
    //       return;
    //     }

    //     let dataListParm = dataList.map(v => v.id);
    //     parm = dataListParm;
    //     // console.log('dataListParm', dataListParm);
    //     // let dataParm = {
    //     //   ids: dataListParm
    //     // };
    //   }

    //   this.postData(this.$urlConfig.WhShelvesComplete, parm, () => {
    //     this.initData();
    //     //  this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
    //     //   type: 'success',
    //     //   
    //     // });
    //     this.$message.success(this.$t('9f30371831a98237'));
    //     // if(row.id){
    //     //   row.dataFlag = '';
    //     //   this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
    //     //     type: 'success',
    //     //     
    //     //   });
    //     // } else {
    //     //   this.initData();
    //     //   this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
    //     //     type: 'success',
    //     //     
    //     //   });
    //     // }
    //   });
    // },

    //删除
    delAction(event, row) {
      event.stopPropagation();
      console.log('completeAction', row);
      let parm = [];

      if (row) {
        //单条
        parm = [row.id];
      } else {
        //多条
        let dataList = this.multipleSelection;
        if (dataList.length < 1) {
          this.$message.warning(this.$t('7b80e66b535a3732'));
          return;
        }

        let dataListParm = dataList.map(v => v.id);
        parm = dataListParm;
        // console.log('dataListParm', dataListParm);
        // let dataParm = {
        //   ids: dataListParm
        // };
      }

      this.postData(this.$urlConfig.WhFinanceRecDel, parm, () => {
        this.initData();
        //  this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
        //   type: 'success',
        //   
        // });
        this.$message.success(this.$t('9f30371831a98237'));
        // if(row.id){
        //   row.dataFlag = '';
        //   this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
        //     type: 'success',
        //     
        //   });
        // } else {
        //   this.initData();
        //   this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
        //     type: 'success',
        //     
        //   });
        // }
      });
    },

    //请求分页数据
    getPageData() {
      this.loading_load = true;

      this.$http
        .put(this.$urlConfig.WhFinanceRecPageList, {
          // sortAsc: this.filterData.sortAsc,
          // orderBy: this.filterData.orderBy,
          offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
          limit: this.pagination.page_size, //当前页显示数目

          // plStatue: this.filterData.plStatue ? this.filterData.plStatue : null,
          opNo: this.filterData.opNo ? this.filterData.opNo : null
          // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
          // goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null
          // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
        })
        .then(({ data }) => {
          console.log('列表，请求成功');
          console.log(data);
          
          this.loading_load = false;
          if (200 == data.code) {
            //表格显示数据
            this.tableData = data.rows;
            //取消编辑时调用
            //缓存，待编辑的数据
            // this.tableDataCatch = data.rows;//直接赋值为浅拷贝 concat也是浅拷贝
            // this.tableDataCatch = deepClone(data.rows);
            //当前数据总条数
            this.pagination.total = parseInt(data.total);
            //当前页数
            // this.pagination.current_page = parseInt(data.current);
            //当前页条数
            // this.pagination.page_size = parseInt(data.size);
            // this.$message.success('订单列表，请求成功');
          } else {
            this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
          }
        })
        .catch(error => {
          console.log(error);
          console.log('列表，请求失败');
          this.$message.error(this.$t('hytxs0000030'));
          this.loading_load = false;
        });
    },

    //提交信息
    postData(url, formData, callBack) {
      // let _this = this;
      this.loading = true;
      // formData.state = formData.state ? '1' : '0';

      this.$http
        .put(url, formData)
        .then(({ data }) => {
          console.log(this.$t('i18nn_bc868e024b80d2e3'));
          console.log(data);
          
          this.loading = false;
          if (200 == data.code) {
            // this.dialogFormVisible = false;
            // this.dialogAddFormVisible = false;
            // this.initData();
            callBack();
          } else {
            if (!data.msg) {
              data.msg = this.$t('dbe331ab679cd67f');
            }
            this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
              type: 'warning',
              
            });
          }
        })
        .catch(error => {
          console.log(error);
          console.log(this.$t('i18nn_a7d2e953195a5588'));
          this.loading = false;
          this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            
          });
        });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// /deep/ .el-table__body {
//   .el-input {
//     input {
//       padding-left: 5px;
//       padding-right: 5px;
//     }
//   }
//   // .el-input-number {
//   //   width: 80px;
//   //   .el-input__inner {
//   //     text-align: left;
//   //   }
//   // }
// }
</style>
